import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import copy from 'copy-to-clipboard';
import { Modal, Form, Input, Button, Row, Col, message } from 'antd';
import { USER_VERIFY_TOTP } from 'graphql/mutations/userMutations';
import { useMutation } from '@apollo/client';
import { getUserId } from 'graphql/cache';

import CloseIcon from 'components/Icons/CloseIcon';
import CopyTOTPIcon from 'components/Icons/CopyTOTPIcon';
import { LoadingOutlined } from '@ant-design/icons';

import './TOTPModal.scss';

const TOTPModal = ({ visible, onCancel, onConfirm, enabling2FA, QrUrl, manualKey, recoveryToken }) => {
  const [savingDisabled, setSavingDisabled] = useState(true);
  const [authKey, setAuthKey] = useState('');
  const [form] = Form.useForm();

  const [verifyOTP, { loading: verifyLoading }] = useMutation(USER_VERIFY_TOTP, {
    onCompleted: (data) => {
      const isValid = data.verifyTOTP;
      setSavingDisabled(!isValid);
      if (isValid) {
        form.setFields([
          {
            name: 'otp',
            errors: [],
            touched: true,
          },
        ]);
      };

      form.validateFields(['otp']);
    },
    onError: (error) => {
      setSavingDisabled(true);
      message.error(error.message);
    },
  });

  const validateOTP = (rule, value, callback) => {
    if (value.length > 0 && value.length < 6) {
      callback('OTP is too short!');
    } else if (value.length === 6 && savingDisabled) {
      callback('OTP is incorrect!');
    } else {
      callback();
    }
  };

  const onInputChange = (e) => {
    const otp = e.target.value;
    setAuthKey(otp);
    form.setFieldsValue({ otp });

    if (otp.length !== 6) {
      setSavingDisabled(true);
    }

    debouncedVerifyOTP(otp, manualKey);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedVerifyOTP = useCallback(
    _.debounce((otp, manualKey) => {
      if (otp.length === 6) {
        verifyOTP({ variables: { userId: getUserId(), otp, secretKey: manualKey } });
      }
    }, 500),
    [verifyOTP]
  );

  const copyToClipboard = (string) => {
    copy(string);
    message.success('Copied to Clipboard');
  };
  

  return (
    <Modal
      className="simple-info-modal totp-modal"
      visible={visible}
      okText="CONTINUE"
      centered={false}
      cancelText="CANCEL"
      closeIcon={<CloseIcon className="close-icon" />}
      footer={null}
      onCancel={onCancel}>
      <h3 className="title">Enable TOTP Authenticator</h3>
      <div className="divider" />

      <div className="body">
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          className="form"
          initialValues={{
            otp: authKey,
          }}>
          <Row className="totp-step">
            <p className="step-title">
              <strong>Step 1:</strong> Scan this QR code in the Authenticator app
            </p>
            <Col className="content">
              <div className="qr-container">
                <img className="image" src={QrUrl} alt="" />
              </div>
              <span className="or-divider">OR</span>
              <div className="step-code">
                <p className="step-description">
                  If you are unable to scan the QR code, please enter this code manually into the
                  app.
                </p>
                <Row gutter={[10, 0]} className="margin-bottom">
                  <Col xs={18} md={20}>
                    <div className="form-item">
                      <Input className="auth-code" placeholder={manualKey} value={manualKey} />
                    </div>
                  </Col>
                  <Col xs={6} md={4}>
                    <Button
                      className="ant-btn ant-btn-ghost copy-totp-button"
                      onClick={() => copyToClipboard(manualKey)}>
                      <CopyTOTPIcon />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="totp-step">
            <p className="step-title">
              <strong>Step 2:</strong> Save this backup key in a secure location
            </p>
            <Row className="content">
              <Col xs={24} md={15} className="step-code">
                <p className="step-description">
                  This Key will allow you to recover your Authenticator when you lose your device.
                </p>
                <Row gutter={[10, 0]} className="margin-bottom">
                  <Col xs={18} md={21}>
                    <div className="form-item">
                      <Input className="auth-code" placeholder={recoveryToken} value={recoveryToken} />
                    </div>
                  </Col>
                  <Col xs={6} md={3}>
                    <Button
                      className="ant-btn ant-btn-ghost copy-totp-button"
                      onClick={() => copyToClipboard(recoveryToken)}>
                      <CopyTOTPIcon />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
          <Row className="totp-step">
            <p className="step-title">
              <strong>Step 3:</strong> Enable Authenticator by verifying your account
            </p>
            <Row className="content">
              <Col xs={24} md={22} className="step-code">
                <p className="step-description">Authenticator Code</p>
                <Row gutter={[10, 0]} className="margin-bottom">
                  <Col xs={24} md={21}>
                    <Form.Item
                      name="otp"
                      rules={[
                        { required: true, message: 'OTP cannot be empty!' },
                        { validator: validateOTP }
                      ]}
                      labelCol={false}
                      hasFeedback
                      shouldUpdate
                      validateStatus={!savingDisabled && authKey.length === 6 ? 'success' : ''}>
                      <Input
                        suffix={
                          !verifyLoading ? (
                            ''
                          ) : (
                            <LoadingOutlined />
                          )
                        }
                        maxLength={6}
                        placeholder="******"
                        onChange={onInputChange}
                        value={authKey}
                        type="text"
                        loading={true}
                      />
                      <span className="form-help">
                        Enter the 6-digit code from Authenticator app.
                      </span>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Form>
      </div>

      <div className="button-wrapper">
        <Button className="button cancel" type="primary" disabled={enabling2FA} onClick={onCancel}>
          CANCEL
        </Button>
        <Button
          className="button confirm"
          type="primary"
          loading={enabling2FA}
          onClick={() => onConfirm(true)}
          disabled={savingDisabled}>
          Enable 2FA Authenticator
        </Button>
      </div>
    </Modal>
  );
};

TOTPModal.defaultProps = {
  visible: false,
  isLoading: false,
};

TOTPModal.propTypes = {
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  selectedProposal: PropTypes.shape({}).isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default TOTPModal;
