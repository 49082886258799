import { gql } from '@apollo/client';

export const UPDATE_PROPOSAL_EMAIL_SETTINGS = gql`
  mutation UpdateProposalEmailSettings(
    $operation: String!
    $topic: String!
    $emailSettings: ProposalEmailSettingsInput!
    $propId: String!
  ) {
    updateProposalEmailSettings(
      operation: $operation
      topic: $topic
      emailSettings: $emailSettings
      propId: $propId
    ) {
      _id
      sharedViaMail
      emailSettings {
        publishedProposalToProspect {
          status
          sent
          date
          reminder
          customize {
            subjectLine {
              RTL
              value
            }
            previewText {
              RTL
              value
            }
            emailBody {
              RTL
              value
            }
            buttonText {
              RTL
              value
            }
            reminder
          }
        }
        reminderToProspect {
          status
          sent
          date
          reminder
          customize {
            subjectLine {
              RTL
              value
            }
            previewText {
              RTL
              value
            }
            emailBody {
              RTL
              value
            }
            buttonText {
              RTL
              value
            }
            reminder
          }
        }
        secondReminderToProspect {
          status
          sent
          date
          reminder
          customize {
            subjectLine {
              RTL
              value
            }
            previewText {
              RTL
              value
            }
            emailBody {
              RTL
              value
            }
            buttonText {
              RTL
              value
            }
            reminder
          }
        }
        signedPDFToProspect {
          status
          sent
          date
          reminder
          customize {
            subjectLine {
              RTL
              value
            }
            previewText {
              RTL
              value
            }
            emailBody {
              RTL
              value
            }
            buttonText {
              RTL
              value
            }
            reminder
          }
        }
        recipients {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const UPDATE_EMAIL_SETTINGS = gql`
  mutation UpdateEmailSettings($settings: CampaignEmailsInput!, $asBrandImage: Boolean) {
    updateEmailSettings(settings: $settings, asBrandImage: $asBrandImage) {
      _id
      generalSettings {
        companyLogo
        buttonColor
        buttonTextColor
        replyTo
        senderName
        font
      }
      publishedProposalToProspect {
        subjectLine {
          value
          RTL
        }
        previewText {
          value
          RTL
        }
        emailBody {
          value
          RTL
        }
        reminder
        sent
        clicked
        open
        buttonText {
          value
          RTL
        }
      }
      reminderToProspect {
        subjectLine {
          value
          RTL
        }
        previewText {
          value
          RTL
        }
        emailBody {
          value
          RTL
        }
        reminder
        sent
        clicked
        open
        buttonText {
          value
          RTL
        }
      }
      secondReminderToProspect {
        subjectLine {
          value
          RTL
        }
        previewText {
          value
          RTL
        }
        emailBody {
          value
          RTL
        }
        reminder
        sent
        clicked
        open
        buttonText {
          value
          RTL
        }
      }
      signedPDFToProspect {
        subjectLine {
          value
          RTL
        }
        previewText {
          value
          RTL
        }
        emailBody {
          value
          RTL
        }
        reminder
        sent
        clicked
        open
        buttonText {
          value
          RTL
        }
      }
    }
  }
`;

export const SEND_TEST_EMAIL = gql`
  mutation sendTestMail($emailType: String!, $email: String!, $template: EmailDataInput!) {
    sendTestMail(emailType: $emailType, template: $template, email: $email)
  }
`;
