import React, { useState } from 'react';
import { Form, Radio, Select, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import PopConfirm from 'components/PopConfirm';

const { Option } = Select;

const CancellationModal = ({ visible, setVisible, userInfo, handleSubmit }) => {
  const paymentStatus = userInfo?.paymentStatus;
  const payPalUser = paymentStatus?.payPalProfile;

  const [form] = Form.useForm();
  const [cancelType, setCancelType] = useState('immediate');
  const [refundType, setRefundType] = useState('no');

  const confirmButtonText =
    paymentStatus?.didCancel ||
    paymentStatus?.canLock ||
    (!paymentStatus?.nextPaymentDate && moment(paymentStatus?.validTill) < moment())
      ? ''
      : 'Ok';

  const isCancellableText = paymentStatus?.didCancel
    ? 'Already Cancelled'
    : paymentStatus?.canLock
    ? 'Account Inactive'
    : !paymentStatus?.nextPaymentDate && !payPalUser?.NEXTBILLINGDATE
    ? 'No Active Subscription'
    : '';
  const selectDisabled = cancelType === 'end' ? 'true' : '';

  const handleCancelType = (event) => {
    if (event?.target?.value) {
      setCancelType(event.target.value);
    } else {
      setRefundType(event);
    }
  };

  return (
    <PopConfirm
      title="Cancel subscription!"
      body={
        <Form
          name="normal_login"
          className="cancellation-form"
          initialValues={{ cancelType: 'immediate', cancelRefund: 'no' }}
          form={form}
          onFinish={() =>
            handleSubmit('cancelSubscription', userInfo, {
              cancelType,
              refundType,
              refundAmount:
                refundType === 'last'
                  ? paymentStatus?.lastInvoice?.amount_paid
                  : Math.abs(
                      paymentStatus?.proratedAmount + paymentStatus?.lastInvoice?.amount_paid
                    ),
              invoiceId: paymentStatus?.lastInvoice?.id,
              invoiceLineId: paymentStatus?.lastInvoice?.lines?.data?.[0]?.id,
              profileId: payPalUser?.PROFILEID,
            })
          }
          layout="horizontal"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}>
          {isCancellableText || (
            <>
              <Form.Item
                label="Cancel"
                name="cancelType"
                rules={[{ required: true, message: 'Please pick an item!' }]}>
                <Radio.Group onChange={handleCancelType}>
                  <Radio value="immediate">Immediately {moment().format('DD MMM, YYYY')}</Radio>
                  <Radio value="end">
                    End of the current period{' '}
                    {moment(paymentStatus?.nextPaymentDate || payPalUser.NEXTBILLINGDATE).format(
                      'DD MMM, YYYY'
                    )}
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Tooltip
                title={
                  selectDisabled
                    ? "You can't issue a refund here when cancelling at the end of the period. However, you can still issue a refund from Refunds options in actions."
                    : ''
                }>
                {!payPalUser && (
                  <Form.Item label="Refund" name="cancelRefund">
                    <Select disabled={selectDisabled} onChange={handleCancelType}>
                      <Option value="no" default>
                        No Refund
                      </Option>
                      <Option value="last">
                        Last Payment ${(paymentStatus?.lastInvoice?.amount_paid || 0) / 100}
                      </Option>
                      <Option value="prorated">
                        Prorated Amount $
                        {Math.abs(
                          (paymentStatus?.proratedAmount +
                            paymentStatus?.lastInvoice?.amount_paid || 0) / 100
                        )}{' '}
                        of ${(paymentStatus?.lastInvoice?.amount_paid || 0) / 100}
                      </Option>
                    </Select>
                  </Form.Item>
                )}
              </Tooltip>
            </>
          )}
        </Form>
      }
      visible={visible}
      cancelText="CANCEL"
      confirmText={confirmButtonText}
      onCancel={() => setVisible(false)}
      onConfirm={() => {
        form.submit();
        setVisible(false);
      }}
    />
  );
};

CancellationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  userInfo: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
export default CancellationModal;
