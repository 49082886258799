import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, DatePicker, Divider, Col, Input, Popover, Button, Avatar, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';

import SearchIcon from 'components/Icons/SearchIcon';
import MultiSelect from 'components/MultiSelect';
import MultiSelectMultiMenu from 'components/MultiSelectMultiMenu';
import InfoIcon from 'components/Icons/InfoIcon';
import FilterIcon from 'components/Icons/FilterIcon';
import CalendarIcon from 'components/Icons/CalendarIcon';
import UsersIcon from 'components/Icons/UsersIcon';
import SortingIcon from 'components/Icons/SortingIcon';
import DownloadReportIcon from 'components/Icons/DownloadReportIcon';
import TeamUsersIcon from 'components/Icons/TeamUsersIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import moment from 'moment';

import './DashboardFilter.scss';
import { useReactiveVar } from '@apollo/client';
import { userVar } from 'graphql/cache';

const { RangePicker } = DatePicker;

const DashboardFilter = ({
  userPlanType,
  canLockUser,
  isTeamMember,
  approvedCount,
  acceptanceRate,
  onChangeSearch,
  filterItems,
  onChangeFilter,
  onChangeTagsFilter,
  selectedDate,
  onChangeDate,
  teamList,
  trash,
  reportItems,
  onChangeTeamsFilter,
  onDownloadReportFilter,
  onShowTrash,
  tagsData,
  tagsType,
  setTagsType,
  sortingList,
  onChangeProposalSorting,
  selectedSorting,
}) => {
  const history = useHistory();
  const [showSearchBox, showHideSearchBox] = useState(false);
  const [teamsVisible, setTeamsVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState('');
  const user = useReactiveVar(userVar);

  const selectedSortName = sortingList.find((item) => item.key === selectedSorting)?.name || '';

  const handleTeamsVisibleChange = (visible) => {
    setActiveFilter(visible ? 'users' : '');
    setTeamsVisible(visible);
  };

  const onPlanClick = () => {
    history.push('/plans');
  };

  const enableDisableDatePicker = () => {
    if (!open) setOpen(true);
  };

  const closeDatePicker = () => {
    setOpen(false);
  };

  const disabledFutureDate = (current) => {
    return current > moment().endOf('day');
  };

  return (
    <div className="dashboard-filter">
      <Row
        justify="left"
        align="middle"
        className="item-filter-menu"
        gutter={[0, { xs: 24, sm: 24, md: 0, lg: 0, xl: 0 }]}>
        <Col xs={24} sm={24} md={24} lg={15} xl={14}>
          <Row>
            <Col xs={24} sm={8} md={8} lg={8} xl={8} className="date-picker-col">
              <RangePicker
                disabledDate={disabledFutureDate}
                bordered={false}
                suffixIcon={
                  <Tooltip title="Date Filter">
                    <CalendarIcon onClick={closeDatePicker} />
                  </Tooltip>
                }
                value={selectedDate}
                className="date-picker "
                placeholder={['Start Date', 'End Date']}
                allowClear
                onFocus={enableDisableDatePicker}
                onChange={(value) => {
                  onChangeDate(value || []);
                  setOpen(false);
                }}
              />
            </Col>
            <Col xs={24} sm={16} md={16} lg={16} xl={16} className="search-filter-col">
              <div className="icons-container">
                <Input
                  className={`search-proposal ${showSearchBox ? 'showInput' : ''}`}
                  placeholder="Search Proposals"
                  allowClear
                  autoFocus
                  onChange={onChangeSearch}
                  suffix={
                    <SearchIcon
                      className={!showSearchBox ? 'search-suffix-hidden' : ''}
                      onClick={() => showHideSearchBox(!showSearchBox)}
                    />
                  }
                />
                <Tooltip title="Search Proposals">
                  <SearchIcon
                    className={`content-search-icon${showSearchBox ? ' hide-search' : ''}`}
                    onClick={() => showHideSearchBox(!showSearchBox)}
                  />
                </Tooltip>

                <MultiSelectMultiMenu
                  filterList={filterItems?.filter((filterItem) => filterItem?.key !== 'deleted')}
                  disabled={trash ? true : false}
                  className={`dashboard-multi-select ${trash ? 'trash-enabled' : ''}`}
                  onChangeFilter={onChangeFilter}
                  onChangeTagsFilter={onChangeTagsFilter}
                  trash={trash}
                  tags={tagsData}
                  tagsType={tagsType}
                  setTagsType={setTagsType}
                  onVisibleChangeHandle={(visible) => setActiveFilter(visible ? 'status' : '')}
                  iconComponent={
                    <Tooltip title="Status Filter">
                      <FilterIcon
                        className={`status-filter ${trash ? 'trash-enabled' : ''} ${
                          activeFilter === 'status' ||
                          filterItems?.some(
                            (filterItem) => filterItem?.checked && filterItem?.key !== 'All'
                          ) ||
                          tagsData?.some((tag) => tag?.checked && tag?._id !== 0)
                            ? 'anticon-active'
                            : ''
                        }`}
                      />
                    </Tooltip>
                  }
                />

                <MultiSelect
                  filterList={sortingList}
                  className="dashboard-multi-select sort-menu"
                  menuClassName={'dashboard-multi-select-menu'}
                  onChangeFilter={onChangeProposalSorting}
                  trash={trash}
                  showHeader={true}
                  headerTitle="Sort By"
                  isRadio={true}
                  disabled={trash ? true : false}
                  onVisibleChangeHandle={(visible) => setActiveFilter(visible ? 'sorting' : '')}
                  iconComponent={
                    <Tooltip title="Sort Proposals">
                      <SortingIcon
                        className={`proposal-sorting ${trash ? 'trash-enabled' : ''} ${
                          activeFilter === 'sorting' || (selectedSorting && selectedSortName.length)
                            ? 'anticon-active'
                            : ''
                        } ${showSearchBox ? 'round-edges' : ''}`}
                      />
                      {!showSearchBox && selectedSorting && selectedSortName.length ? (
                        <span className="sort-text">{selectedSortName}</span>
                      ) : (
                        ''
                      )}
                    </Tooltip>
                  }
                />

                {teamList.length <= 1 && !isTeamMember ? (
                  <Popover
                    content={
                      <Col>
                        <Avatar
                          size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                          icon={<TeamUsersIcon />}
                        />
                        <h3>Upgrade to Invite Your Team</h3>
                        <Divider />
                        <p>
                          Close even more deals with them for only $
                          {userPlanType === 'new' && !canLockUser
                            ? 10
                            : userPlanType === '2025' || canLockUser
                            ? 19
                            : 8}{' '}
                          a month per user.
                        </p>
                        <Button
                          size="middle"
                          type="button"
                          className="button"
                          onClick={onPlanClick}>
                          {` 🎉 Upgrade now!`}
                        </Button>
                      </Col>
                    }
                    placement="bottomLeft"
                    overlayClassName="teams-dashboard-popup"
                    trigger={trash ? '' : 'click'}
                    visible={teamsVisible}
                    trash={trash}
                    onVisibleChange={handleTeamsVisibleChange}>
                    <Tooltip title="Users Filter">
                      <UsersIcon
                        className={`teams-filter ${trash ? 'trash-enabled' : ''} ${
                          activeFilter === 'users' ? 'anticon-active' : ''
                        }`}
                      />
                    </Tooltip>
                  </Popover>
                ) : !isTeamMember || user?.roles?.includes('manager') ? (
                  <MultiSelect
                    filterList={teamList}
                    className="dashboard-multi-select"
                    menuClassName={'dashboard-multi-select-menu'}
                    onChangeFilter={onChangeTeamsFilter}
                    trash={trash}
                    disabled={trash ? true : false}
                    onVisibleChangeHandle={(visible) => setActiveFilter(visible ? 'users' : '')}
                    iconComponent={
                      <Tooltip title="Users Filter">
                        <UsersIcon
                          className={`teams-filter ${trash ? 'trash-enabled' : ''} ${
                            activeFilter === 'users' || teamList?.find((team) => team?.checked)
                              ? 'anticon-active'
                              : ''
                          }`}
                        />
                      </Tooltip>
                    }
                  />
                ) : null}
                <Divider type="vertical" className="vertical-divider" />

                <MultiSelect
                  filterList={reportItems}
                  hideCheckBox={true}
                  className="dashboard-multi-select"
                  menuClassName={'dashboard-multi-select-menu'}
                  trash={trash}
                  onChangeFilter={onDownloadReportFilter}
                  onVisibleChangeHandle={(visible) => setActiveFilter(visible ? 'report' : '')}
                  iconComponent={
                    <Tooltip title="Download Report">
                      <DownloadReportIcon
                        className={`download-report-icon ${trash ? 'trash-enabled' : ''} ${
                          activeFilter === 'report' ? 'anticon-active' : ''
                        }`}
                      />
                    </Tooltip>
                  }
                />
                <Col onClick={onShowTrash}>
                  <Tooltip title="Trash">
                    <TrashIcon className={`trash-icon ${trash ? 'selected' : ''}`} />
                  </Tooltip>
                </Col>
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24} lg={9} xl={10}>
          <Row className="dashboard-rate" justify="end" align="middle">
            <div>
              <div className="item-filter-title" justify="start">
                {approvedCount}
              </div>
              <div className="item-filter-value" justify="start">
                Approved Proposals
              </div>
            </div>
            <div className="divider" />
            <div>
              <div className="item-filter-title" justify="start">
                {acceptanceRate}%
              </div>
              <div className="item-filter-value" justify="start">
                <span>Acceptance Rate</span>
                <Tooltip
                  overlayClassName="acceptance-rate-tip"
                  title="The total number of approved proposals divided by the total number of published proposals of any status (excluding drafts and deleted proposals)."
                  color="black"
                  placement="bottomRight"
                  key="white">
                  <InfoIcon className="info-icon" />
                </Tooltip>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

DashboardFilter.defaultProps = {
  canLockUser: false,
  selectedDate: null,
  teamList: [],
  isTeamMember: false,
};

DashboardFilter.propTypes = {
  userPlanType: PropTypes.string,
  canLockUser: PropTypes.bool,
  approvedCount: PropTypes.number.isRequired,
  acceptanceRate: PropTypes.number.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onChangeTeamsFilter: PropTypes.func.isRequired,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  filterItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  reportItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamList: PropTypes.arrayOf(PropTypes.shape({})),
  onDownloadReportFilter: PropTypes.func.isRequired,
  isTeamMember: PropTypes.bool,
};

export default DashboardFilter;
