import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_17158_26319)">
      <path
        d="M7.99935 14.6666C7.99935 14.6666 13.3327 11.9999 13.3327 7.99992V3.33325L7.99935 1.33325L2.66602 3.33325V7.99992C2.66602 11.9999 7.99935 14.6666 7.99935 14.6666Z"
        stroke="#FF1B1B"
        strokeWidth="1.336"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <rect
      x="0.22168"
      y="14.364"
      width="20"
      height="1.5"
      rx="0.75"
      transform="rotate(-45 0.22168 14.364)"
      fill="#FF1B1B"
    />
    <defs>
      <clipPath id="clip0_17158_26319">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const TFAShieldCancelIcon = (props) => <Icon component={svg} {...props} />;

export default TFAShieldCancelIcon;
