import React from 'react';
import { Modal } from 'antd';

import Loader from 'components/Loader';

const UploadProgressModal = () => {
  return (
    <Modal visible centered footer={null} closeIcon={null} closable={false}>
      <Loader />
      <h1 style={{ textAlign: 'center' }}>Uploading</h1>
    </Modal>
  );
};

export default UploadProgressModal;
