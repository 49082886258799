import React, { useState } from 'react';
import { Button, Table, message, Space, Dropdown, Menu, Tooltip } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';

import { FETCH_ADMIN_MAIL_JOBS } from 'graphql/queries/adminQueries';
import { RESEND_ADMIN_MAIL_JOB } from 'graphql/mutations/adminMutations';
import FilterIcon from 'components/Icons/FilterIcon';

const ReminderMails = () => {
  const [mailJobs, setMailJobs] = useState(0);
  const [mailJobsCount, setMailJobsCount] = useState(0);
  const [mailJobsType, setMailJobsType] = useState('');
  const [limit, setLimit] = useState(30);
  const [offset, setOffset] = useState(0);

  const { loading: isFetchingAdminMailJobs, refetch } = useQuery(FETCH_ADMIN_MAIL_JOBS, {
    variables: {
      type: mailJobsType,
      limit: limit,
      offset: offset,
      reminder: true,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchAdminMailJobs }) => {
      setMailJobs(fetchAdminMailJobs?.mailJobs);
      setMailJobsCount(fetchAdminMailJobs?.mailJobsCount);
    },
  });

  const [resendAdminMailJob] = useMutation(RESEND_ADMIN_MAIL_JOB, {
    onCompleted: () => message.success('Job mail added to queue successfully'),
    onError: () => message.error(`Failed to resend mail`),
  });

  const refetchMailJobs = async (type) => {
    const updatedJobs = await refetch({
      variables: {
        type: type || mailJobsType,
        limit: limit,
        offset: offset,
      },
    });
    setMailJobs(updatedJobs?.data?.fetchAdminMailJobs?.mailJobs);
  };

  const jobAction = async (data) => {
    if (data !== true)
      resendAdminMailJob({
        variables: {
          jobId: data._id,
        },
      });
    refetchMailJobs();
  };

  const filterMailJobs = ({ key }) => {
    setMailJobsType(key);
    refetchMailJobs(key);
  };

  const mailJobsColumn = [
    {
      title: 'Created At',
      dataIndex: 'time',
      key: 'time',
      render: (data) => <>{moment(data).format('DD/MM/YYYY')}</>,
    },
    {
      title: 'User ID',
      dataIndex: 'userid',
      key: 'userid',
    },
    {
      title: 'Proposal ID',
      dataIndex: 'propid',
      key: 'propid',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => (
        <>{type === '1st_reminder_to_prospect' ? '1st email reminder' : '2nd email reminder'}</>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'done',
      key: 'done',
      render: (status) => (
        <>{status === true ? 'Completed' : status === false ? 'Not started' : status}</>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (data) => (
        <Button disabled={typeof data?.done === 'boolean'} onClick={() => jobAction(data)}>
          {data?.done === true ? 'Sent' : data?.done === false ? 'Not Sent' : 'Re-send'}
        </Button>
      ),
    },
  ];

  const menu = (
    <Menu onClick={filterMailJobs} defaultSelectedKeys={['all']}>
      <Menu.Item key="all">All</Menu.Item>
      <Menu.Item key="sent">Sent</Menu.Item>
      <Menu.Item key="inprogress">In Progress</Menu.Item>
    </Menu>
  );

  return (
    <div className="admin-wrapper">
      <div className="admin-table-wrapper">
        <div className="table-action">
          <h3>Signature Mail Jobs</h3>
          <div>
            <Space>
              <Dropdown overlay={menu}>
                <Tooltip title="Status Filter">
                  <FilterIcon />
                </Tooltip>
              </Dropdown>
            </Space>
          </div>
        </div>

        <Table
          loading={isFetchingAdminMailJobs}
          dataSource={mailJobs}
          columns={mailJobsColumn}
          rowKey={'_id'}
          pagination={{
            total: mailJobsCount,
            defaultPageSize: limit,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30'],
            onChange: async (page, size) => {
              setLimit(size);
              setOffset(page - 1);
            },
          }}
        />
      </div>
    </div>
  );
};

export default ReminderMails;
