import { useMutation } from '@apollo/client';
import { useIntercom } from 'react-use-intercom';
import { message } from 'antd';
import { CREATE_STRIPE_SESSION } from 'graphql/mutations/transactionMutation';

const useCheckout = ({ stripe, setStripeLoading }) => {
  const { trackEvent } = useIntercom();

  const {
    REACT_APP_STRIPE_2025_MONTHLY_PLAN_ID,
    REACT_APP_STRIPE_2025_YEARLY_PLAN_ID,
    REACT_APP_PLAN_REVISE_DATE,
  } = process.env;

  const [createStripeSession] = useMutation(CREATE_STRIPE_SESSION, {
    onCompleted: async ({ createStripeSession: session }) => {
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (result.error) {
        setStripeLoading(false);
        message.error('Something went wrong during checkout');
      }
    },
    onError: (error) => {
      setStripeLoading(false);
      message.error('Checkout session could not be created');
      console.error(error);
    },
  });

  const getPlanId = ({ user, plan, trial }) => {
    const userPlanType =
      user?.planType ||
      (new Date(user?.createdAt) > new Date(REACT_APP_PLAN_REVISE_DATE) ? 'new' : '');

    if ((trial || !user?.paymentStatus?.everPayed) && userPlanType && !user?.subscriptionId) {
      return plan === 'monthly'
        ? REACT_APP_STRIPE_2025_MONTHLY_PLAN_ID
        : REACT_APP_STRIPE_2025_YEARLY_PLAN_ID;
    }
    return null;
  };

  // Initiates the checkout process.
  const handleCheckout = ({ user, plan, trial, mutationInfo = {} }) => {
    const planId = getPlanId({ user, plan, trial });

    trackEvent('click-upgrade', { type: 'upgrade', uid: user._id });
    trackEvent('click-buynow', {
      type: 'upgrade',
      subtype: planId === 96 || planId === 60 ? 'yearly' : 'monthly',
    });

    if (!planId) {
      setStripeLoading(false);
      message.error('Unable to determine plan. Please check your details.');
      return;
    }

    createStripeSession({
      variables: {
        price: planId,
        membersToBeAdded: mutationInfo.membersToBeAdded || [],
        membersToBeDeleted: mutationInfo.membersToBeDeleted || [],
        planInfo: mutationInfo.planInfo || {},
      },
    });
  };

  return { handleCheckout };
};

export default useCheckout;
