import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Divider } from 'antd';

import IconButton from 'components/IconButton';
import TeamActivityLog from 'components/Icons/TeamActivityLog';
import TeamRoleIcon from 'components/Icons/TeamRoleIcon';
import TeamDashboard from 'components/Icons/TeamDashboard';
import TeamMembersIcon from 'components/Icons/TeamMembersIcon';
import TadaIcon from 'components/Icons/TadaIcon';

import './EmptyUser.scss';

const EmptyUser = ({ addOreditUser, planType }) => {
  return (
    <Card className="empty-user">
      <Row justify="left" className="invite-row">
        <Col>
          <TeamMembersIcon />
        </Col>
        <Col className="invite-col">
          <h3>
            Invite Your Team to the Party <TadaIcon />
          </h3>
          <p>
            Close even more deals with them for only $
            {planType === 'new' ? 10 : planType === '2025' ? 19 : 8} a month.
          </p>
          <IconButton
            text="ADD NEW USER"
            onButtonClick={() => addOreditUser({ actionType: 'add' })}
            className="invite-button"
          />
        </Col>
      </Row>
      <Row justify="center" className="builtin-row">
        <Col className="builtin-col">
          <h4>BUILT-IN FOR TEAMWORK </h4>
          <h3>Team features for success</h3>
        </Col>
      </Row>

      <Row gutter={56} justify="center">
        <Col flex="0 1 280px">
          <TeamActivityLog />
          <h4 className="sub-header">Activity Log</h4>
          <p className="sub-header-value">
            Easily keep track of your team's proposal related activity, keep tabs on how they are
            doing with the proposals.
          </p>
        </Col>
        <Divider type="vertical" />
        <Col flex="0 1 280px">
          <TeamRoleIcon />
          <h4 className="sub-header">Permissions and Roles</h4>
          <p className="sub-header-value">
            Assign different roles to members of your team to manage, control and collaborate
            effortlessly within your team.
          </p>
        </Col>
        <Divider type="vertical" />
        <Col flex="0 1 280px">
          <TeamDashboard />
          <h4 className="sub-header">Individualized Dashboard</h4>
          <p className="sub-header-value">
            Each members getting their own content library, dashboard &amp; credentials, no need to
            share password or compromise security.
          </p>
        </Col>
      </Row>
    </Card>
  );
};

EmptyUser.propTypes = {
  addOreditUser: PropTypes.func.isRequired,
  planType: PropTypes.string,
};

export default EmptyUser;
