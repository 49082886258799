import React, { forwardRef } from 'react';
import { Input as AntInput, Row } from 'antd';
import PropTypes from 'prop-types';

import './Input.scss';

const { TextArea } = AntInput;

const shorten = (str, len, prefix) => {
  if (!str) return '';
  if (typeof str !== 'string' || str.length <= len) return str;
  return `${str.substring(0, len)}${prefix}`;
};

const Input = forwardRef((props, ref) => {
  const {
    containerclass,
    labelclass,
    sublabelclass,
    icon,
    label,
    sublabel,
    type,
    suffixtext,
    prefixtext,
    customstyle,
    status,
  } = props;

  return (
    <div className={`input-container ${status} ${containerclass}`} style={customstyle}>
      {label && (
        <Row>{label && <label className={`input-label ${labelclass}`}>{label}</label>}</Row>
      )}
      {icon && <div className="input-icon">{icon}</div>}
      {sublabel && <p className={`input-sub-label ${sublabelclass}`}>{sublabel}</p>}
      {prefixtext && <p className="input-prefix-text">{prefixtext}</p>}
      {suffixtext && <p className="input-suffix-text">{shorten(suffixtext, 3, '...')}</p>}
      {type === 'textarea' ? <TextArea {...props} /> : <AntInput ref={ref} {...props} />}
    </div>
  );
});

Input.defaultProps = {
  containerclass: '',
  labelclass: '',
  sublabelclass: '',
  icon: '',
  label: '',
  sublabel: '',
  type: '',
  customstyle: {},
};

Input.propTypes = {
  containerclass: PropTypes.string,
  labelclass: PropTypes.string,
  sublabelclass: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.string,
  sublabel: PropTypes.string,
  type: PropTypes.string,
  customstyle: PropTypes.instanceOf(Object),
};

export default Input;
