import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6667 8H9.16667C8.24619 8 7.5 8.74619 7.5 9.66667V17.1667C7.5 18.0871 8.24619 18.8333 9.16667 18.8333H16.6667C17.5871 18.8333 18.3333 18.0871 18.3333 17.1667V9.66667C18.3333 8.74619 17.5871 8 16.6667 8Z"
      stroke="#4F4E7B"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.16699 13H3.33366C2.89163 13 2.46771 12.8244 2.15515 12.5119C1.84259 12.1993 1.66699 11.7754 1.66699 11.3334V3.83335C1.66699 3.39133 1.84259 2.9674 2.15515 2.65484C2.46771 2.34228 2.89163 2.16669 3.33366 2.16669H10.8337C11.2757 2.16669 11.6996 2.34228 12.0122 2.65484C12.3247 2.9674 12.5003 3.39133 12.5003 3.83335V4.66669"
      stroke="#4F4E7B"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const CopyTOTPIcon = (props) => <Icon component={svg} {...props} />;

export default CopyTOTPIcon;
