import { gql } from '@apollo/client';

export const FETCH_USER_INVOICES = gql`
  query fetchUserInvoicesMutations {
    fetchUserInvoices
  }
`;

export const FETCH_USER_PRORATION = gql`
  query fetchUserProrationQuery($numberOfTeamMembers: Float, $plan: String, $coupon: String) {
    fetchUserProration(numberOfTeamMembers: $numberOfTeamMembers, plan: $plan, coupon: $coupon)
  }
`;
