import React, { useEffect, useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Row, Select, AutoComplete } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { getUserId, userVar, proposalsClientVar } from 'graphql/cache';
import { GET_PROPOSALS_CLIENTS } from 'graphql/queries/proposalQueries';

import './ClientName.scss';

const { Option } = Select;

const ClientName = ({
  type,
  action,
  onChange,
  clientNameValue,
  label,
  placeholder,
  dupClientErr,
  fromTemplate,
  language,
}) => {
  const proposalsClients = useReactiveVar(proposalsClientVar);
  const user = useReactiveVar(userVar);

  const [clientName, setClientName] = useState(clientNameValue);
  const [clientNames, setClientNames] = useState(proposalsClients);

  useEffect(() => {
    setClientName(clientNameValue);
  }, [clientNameValue]);

  useQuery(GET_PROPOSALS_CLIENTS, {
    skip: !getUserId() || proposalsClients.length,
    variables: {
      uid: getUserId(),
    },
    onCompleted: ({ fetchProposals: { proposals } }) => {
      let freshbooksClients = [];
      if (user && user.freshbooks && user.freshbooks.clients && user.freshbooks.clients.clients) {
        freshbooksClients = user.freshbooks.clients.clients.map((x) => ({
          value: x.fname + (x.lname ? ` ${x.lname}` : ''),
          extra: { data: x, type: 'freshbooks' },
        }));
      }
      const freshbooksMap = _.keyBy(freshbooksClients, 'value');

      let tempClientNames = _.compact(
        _.map(
          proposals,
          (p) =>
            p.client &&
            p.client.contact &&
            !freshbooksMap[p.client.contact] && { value: p.client.contact }
        )
      );

      tempClientNames = _.uniqBy([...tempClientNames, ...freshbooksClients], 'value');

      setClientNames(tempClientNames);
      proposalsClientVar(tempClientNames);
    },
  });

  // Disabled debounce
  // const bubbleChange = useRef(
  //   _.debounce((type, action, value) => {
  //     onChange({ type, action, value });
  //   }, 800)
  // ).current;

  const onInputChange = (value) => {
    if (fromTemplate) {
      onChange(value);
    } else {
      onChange({ type, action, value });
    }
    setClientName(value);
    // Disabled debounce
    // bubbleChange(type, action, value);
  };

  return (
    <div className={`autocomplete-wrapper input-container ${!clientName ? 'error' : ''}`}>
      {label && (
        <Row>
          <label className="input-label">
            {label} <span className="asterisk">*</span>
          </label>
        </Row>
      )}
      <AutoComplete
        placeholder={placeholder}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        value={clientName || ''}
        onChange={onInputChange}>
        {clientNames.map((client, clientIndex) => (
          <Option key={`${client.value}${clientIndex}`} value={client.value} className={language}>
            {client.value}
          </Option>
        ))}
      </AutoComplete>
      <p>{dupClientErr}</p>
    </div>
  );
};

ClientName.defaultProps = {
  dupClientErr: '',
  clientNameValue: '',
};

ClientName.propTypes = {
  type: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  clientNameValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  dupClientErr: PropTypes.string,
  language: PropTypes.string,
};

export default ClientName;
