import React from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import CloseIcon from 'components/Icons/CloseIcon';

const SaveAsDefaultModal = ({ onConfirm, onCancel, prompt, description }) => {
  return (
    <Modal
      className="simple-info-modal"
      centered
      visible
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseIcon />}>
      {prompt && (
        <>
          <h3 className="title">{prompt}</h3>
          <div className="divider" />
        </>
      )}

      {description && <p className="body">{description}</p>}

      <div className="button-wrapper">
        <Button className="button confirm" type="primary" onClick={onConfirm}>
          YES
        </Button>
        <Button className="button cancel" type="primary" onClick={onCancel}>
          NO
        </Button>
      </div>
    </Modal>
  );
};

SaveAsDefaultModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  prompt: PropTypes.string,
};

export default SaveAsDefaultModal;
