import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_17520_53595)">
      <path
        opacity="0.4"
        d="M9.57771 2.20769C10.0006 1.78477 10.6177 1.62089 11.1948 1.77826L15.8246 3.04095C16.394 3.19623 16.8388 3.64101 16.994 4.21036L18.2567 8.84023C18.4141 9.41725 18.2502 10.0343 17.8273 10.4573L10.2616 18.0229C9.61076 18.6738 8.55548 18.6738 7.90461 18.0229L2.01205 12.1304C1.36118 11.4795 1.36118 10.4242 2.01205 9.77335L9.57771 2.20769Z"
        fill="#7876C1"
      />
      <circle
        cx="14.7788"
        cy="5.25517"
        r="0.833333"
        transform="rotate(45 14.7788 5.25517)"
        fill="#7876C1"
      />
      <path
        d="M9.84195 13.7284C9.58515 13.9852 9.28125 14.1653 8.93027 14.2686C8.58881 14.3716 8.22477 14.378 7.83816 14.2877C7.46108 14.197 7.09969 14.0036 6.754 13.7073L6.15397 14.3073L5.71375 13.8671L6.32119 13.2596C5.90707 12.7566 5.68608 12.2393 5.65822 11.7077C5.6353 11.1712 5.83655 10.6811 6.26197 10.2373L7.29374 11.2691C6.96568 11.6522 6.92052 12.0367 7.15827 12.4226L8.57316 11.0077C8.33188 10.5886 8.15481 10.2337 8.04193 9.94308C7.92906 9.65242 7.89802 9.33495 7.94882 8.99067C7.99962 8.64639 8.1954 8.30387 8.53616 7.96311C8.96581 7.53346 9.4586 7.32498 10.0145 7.33767C10.575 7.35495 11.102 7.57082 11.5955 7.98529L12.1956 7.38526L12.6358 7.82548L12.0358 8.42551C12.4365 8.90524 12.6441 9.39926 12.6585 9.90757C12.6775 10.4205 12.4858 10.9103 12.0833 11.3769L11.0447 10.3383C11.1741 10.1813 11.2495 10.0097 11.2706 9.82346C11.3013 9.63686 11.2724 9.45484 11.1839 9.27741L9.78379 10.6775C10.0339 11.1152 10.2131 11.4772 10.3214 11.7632C10.4392 12.049 10.4727 12.364 10.4219 12.7082C10.376 13.0476 10.1827 13.3876 9.84195 13.7284ZM9.59227 8.91551C9.4293 9.07848 9.34181 9.25768 9.32982 9.4531C9.32276 9.64358 9.37426 9.85805 9.48431 10.0965L10.7659 8.81496C10.5761 8.67457 10.3787 8.61055 10.1738 8.6229C9.96883 8.63525 9.77499 8.73279 9.59227 8.91551ZM7.58368 12.8776C7.7925 13.0173 8.00432 13.076 8.21915 13.0538C8.43855 13.0361 8.63221 12.9434 8.80012 12.7755C8.9631 12.6125 9.04582 12.4335 9.04829 12.2384C9.05076 12.0433 8.99468 11.8243 8.88004 11.5812L7.58368 12.8776Z"
        fill="#7876C1"
      />
    </g>
    <defs>
      <clipPath id="clip0_17520_53595">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const DollarDiscountIcon = (props) => <Icon component={svg} {...props} />;

export default DollarDiscountIcon;
