/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 12.6667L11 8M11 8L15 12.6667M11 8V22"
      stroke="#5D5AB5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25 19.3333L21 24M21 24L17 19.3333M21 24L21 10"
      stroke="#C7BCE4"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const UsersIcon = (props) => <Icon component={svg} {...props} />;

export default UsersIcon;
