import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.99935 14.6666C5.99935 14.6666 11.3327 11.9999 11.3327 7.99992V3.33325L5.99935 1.33325L0.666016 3.33325V7.99992C0.666016 11.9999 5.99935 14.6666 5.99935 14.6666Z"
      stroke="#05034D"
      strokeWidth="1.336"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const TFAShieldIcon = (props) => <Icon component={svg} {...props} />;

export default TFAShieldIcon;
