import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Form, Input, message, Row, Button, Checkbox } from 'antd';
import { useMutation } from '@apollo/client';
import { USER_VERIFY_TOTP } from 'graphql/mutations/userMutations';
import utils from 'utils/utils';

import './Verification.scss';

const Verification = ({ clickShowRecovery, onVerified, pendingLogin, clickShowLogin }) => {
  const [otpDigits, setOtpDigits] = useState(Array(6).fill(''));
  const [form] = Form.useForm();
  const inputRefs = useRef([]);

  useEffect(() => {
    const skipCookie = utils.readCookie(`skipTOTP_${pendingLogin?.user?._id}`);
    if (skipCookie && skipCookie === 'true') {
      onVerified();
    }
  }, [onVerified, pendingLogin]);

  const [verifyOTP, { loading: verifyLoading }] = useMutation(USER_VERIFY_TOTP, {
    onCompleted: (data) => {
      if (data.verifyTOTP) {
        if (form.getFieldValue('remember')) {
          utils.createCookie(`skipTOTP_${pendingLogin?.user?._id}`, true, 30);
        }
        onVerified();
      } else {
        message.error('Verification failed, confirm OTP!');
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const throttledVerify = useMemo(
    () =>
      _.throttle((userId, otp) => {
        verifyOTP({ variables: { userId, otp } });
      }, 2000),
    [verifyOTP]
  );

  useEffect(() => {
    return () => {
      throttledVerify.cancel();
    };
  }, [throttledVerify]);

  const handleInputChange = (e, index) => {
    const digit = e.target.value.slice(-1);
    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = digit;
    setOtpDigits(newOtpDigits);

    if (digit && index < otpDigits.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('Text').trim();
    if (!paste) return;
    const pasteDigits = paste.split('').filter((char) => /\d/.test(char));
    if (pasteDigits.length === 0) return;
  
    const newOtpDigits = [...otpDigits];
    let pasteCount = 0;
    for (let i = index; i < newOtpDigits.length && pasteDigits.length > 0; i++) {
      newOtpDigits[i] = pasteDigits.shift();
      pasteCount++;
    }
    setOtpDigits(newOtpDigits);
  
    const nextInputIndex = index + pasteCount;
    if (nextInputIndex < newOtpDigits.length) {
      inputRefs.current[nextInputIndex]?.focus();
    } else {
      inputRefs.current[newOtpDigits.length - 1]?.focus();
    }
  
    if (newOtpDigits.every((digit) => digit !== '')) {
      const combinedOtp = newOtpDigits.join('');
      const userId = pendingLogin?.user?._id;
      throttledVerify(userId, combinedOtp);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otpDigits[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleVerifyClick = () => {
    const combinedOtp = otpDigits.join('');
    if (combinedOtp.length !== 6) {
      message.error('Please fill in the 6-digit code');
      return;
    }
    const userId = pendingLogin?.user?._id;
    throttledVerify(userId, combinedOtp);
  };

  return (
    <div className="content-wrapper totp-verification">
      <div className="heading-wrap">
        <h1 className="heading">Security Verification</h1>
        <p className="sub-heading-text">Enter the 6-digit code from your Authenticator app.</p>
        <div className="color-divider" />
      </div>
      <Form form={form} layout="vertical" className="form">
        <Row className="margin-top" id="inputs">
          {otpDigits.map((digit, index) => (
            <Input
              key={index}
              type="text"
              maxLength="1"
              value={digit}
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={(e) => handlePaste(e, index)}
              ref={(input) => (inputRefs.current[index] = input)}
              disabled={verifyLoading}
            />
          ))}
        </Row>
        <Form.Item name="remember" className="remember-browser" valuePropName="checked">
          <Checkbox tabIndex={3}>
            Don't ask me again for the next 30 days.
          </Checkbox>
        </Form.Item>
        <div className="button-wrapper">
        <Button
                type="primary"
                block
                className="login-btn large-btn"
                tabIndex={4}
                onClick={handleVerifyClick}
                disabled={verifyLoading}
                loading={verifyLoading}>
                <span className="btn-text">SUBMIT</span>
              </Button>
        </div>
        <div className="login-link">
          <a><span onClick={clickShowRecovery}>Recover Account?</span></a>
        </div>
        <div className="login-divider">
              <div className="inner-login-divider"> Or </div>
            </div>
            <div className="login-link">
              Go back to{' '}
              <a><span onClick={clickShowLogin}>Sign In</span></a>
        </div>
      </Form>
    </div>
  );
};

Verification.propTypes = {
  onVerified: PropTypes.func.isRequired,
  pendingLogin: PropTypes.object,
  clickShowRecovery: PropTypes.func.isRequired,
  clickShowLogin: PropTypes.func.isRequired,
};

export default Verification;
