import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, message, Button } from 'antd';
import { useMutation } from '@apollo/client';
import { USER_MFA_RECOVERY } from 'graphql/mutations/userMutations';

const RecoverAccount = ({ onRecovered, pendingLogin, clickShowLogin }) => {
  const [recoveryToken, setRecoveryToken] = useState('');
  const [form] = Form.useForm();

  const [recoverAccount, { loading }] = useMutation(USER_MFA_RECOVERY, {
    onCompleted: (data) => {
      if (data.recoverMFAUser) {
        message.success('Account recovered successfully!');
        onRecovered();
      } else {
        message.error('Invalid recovery token!');
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    setRecoveryToken(value);
  };

  const handleSubmit = () => {
    const userId = pendingLogin?.user?._id;
    recoverAccount({ variables: { userId, recoveryToken } });
  };

  return (
    <div className="content-wrapper totp-verification">
      <div className="heading-wrap">
        <h1 className="heading">Recover Account</h1>
        <p className="sub-heading-text">To recover your account, please enter the recovery code</p>
        <div className="color-divider" />
      </div>
      <Form form={form} layout="vertical" className="form" onFinish={handleSubmit}>
        <div className="margin-top">
          <Form.Item
            label=""
            name="recoveryToken"
            labelCol={false}
            rules={[
              {
                required: true,
                message: 'Please input your recovery code',
              },
            ]}>
            <Input
              type="text"
              value={recoveryToken}
              className="recovery-input"
              onChange={(e) => handleInputChange(e)}
              disabled={loading}
            />
          </Form.Item>
        </div>
        <div className="button-wrapper">
          <Button
            type="primary"
            htmlType="submit"
            block
            className="login-btn large-btn"
            tabIndex={4}
            disabled={loading}
            loading={loading}>
            <span className="btn-text">SUBMIT</span>
          </Button>
        </div>
        <div className="login-divider">
          <div className="inner-login-divider"> Or </div>
        </div>
        <div className="login-link">
          Go back to <a><span onClick={clickShowLogin}>Sign In</span></a>
        </div>
      </Form>
    </div>
  );
};

RecoverAccount.propTypes = {
  onRecovered: PropTypes.func.isRequired,
  pendingLogin: PropTypes.object,
  clickShowLogin: PropTypes.func.isRequired,
};

export default RecoverAccount;
