import React, { useState, useEffect } from 'react';
import { message, Row } from 'antd';
import { useIntercom } from 'react-use-intercom';
import { useMutation, useLazyQuery, useReactiveVar } from '@apollo/client';

import { USER_PAYMENT_DETAILS } from 'graphql/queries/userQueries';
import { FETCH_USER_INVOICES } from 'graphql/queries/transactionsQueries';
import {
  CREATE_STRIPE_SESSION,
  UPDATE_OLD_PAYPAL_SUBSCRIPTION,
  CREATE_STRIPE_CARD_SESSION,
  UPDATE_STRIPE_CARD,
} from 'graphql/mutations/transactionMutation';
import { FETCH_TEAM_MEMBERS } from 'graphql/queries/usersTeamQueries';
import { getUserId, userVar } from 'graphql/cache';
import { PlansInfo, BillingInfo } from './components';

import Loader from 'components/Loader';
import utils from 'utils/utils';
import PageHeader from 'components/PageHeader';

import './Plans.scss';

const Plans = ({ history }) => {
  const { trackEvent } = useIntercom();
  const user = useReactiveVar(userVar) || {};

  const [stripeLoading, setStripeLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usersTeam, setUsersTeam] = useState([]);
  const [pendingUsersTeam, setPendingUsersTeam] = useState([]);
  const [loadingTeamMembers, setLoadingTeamMembers] = useState(false);
  const [stripe, setStripe] = useState('');

  const [loadUser, { loading: loadingUser, startPolling, stopPolling, refetch: refetchUser }] =
    useLazyQuery(USER_PAYMENT_DETAILS, {
      skip: user?.paymentStatus?.payments?.length || user?.teamId,
      variables: { id: getUserId() },
      fetchPolicy: 'network-only',
      onCompleted: ({ fetchUser }) => {
        (async () =>
          import('@stripe/stripe-js').then(async (stripe) =>
            setStripe(await stripe.loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))
          ))();
        userVar({ ...user, ...fetchUser });
      },
    });

  const [loadTeamMembers, { refetch: refetchTeamMembers }] = useLazyQuery(FETCH_TEAM_MEMBERS, {
    variables: { id: user?._id },
    skip: !user?._id,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ fetchTeamMembers }) => {
      setUsersTeam(fetchTeamMembers);
      setPendingUsersTeam(fetchTeamMembers.filter((team) => team?.emails[0]?.verified === false));
      setLoadingTeamMembers(false);
    },
  });

  const [loadInvoices, { loading: loadingInvoices, data: invoices, refetch: refetchInvoices }] =
    useLazyQuery(FETCH_USER_INVOICES, {
      fetchPolicy: 'cache-and-network',
    });

  const [createStripeSession] = useMutation(CREATE_STRIPE_SESSION, {
    async onCompleted(data) {
      const result = await stripe.redirectToCheckout({
        sessionId: data.createStripeSession.id,
      });

      if (result.error) {
        message.error('Something went wrong');
      }
      setStripeLoading(false);
    },
    onError(error) {
      setStripeLoading(false);
      console.error(error);
      message.error('Something went wrong');
    },
  });

  const [createStripeCardSession] = useMutation(CREATE_STRIPE_CARD_SESSION, {
    async onCompleted(data) {
      const result = await stripe.redirectToCheckout({
        sessionId: data.createStripeCardSession.id,
      });

      if (result.error) {
        message.error('Something went wrong');
      }
      setStripeLoading(false);
    },
    onError(error) {
      setStripeLoading(false);
      console.error(error);
      message.error('Something went wrong');
    },
  });

  const [updateStripeCard] = useMutation(UPDATE_STRIPE_CARD, {
    async onCompleted(data) {
      if (data) {
        setLoading(false);
        const updatedUser = await refetchUser();
        if (updatedUser?.data?.fetchUser) {
          userVar({ ...user, ...updatedUser?.data?.fetchUser });
        }
        history.push('/plans');
      }
    },
    onError(error) {
      setLoading(false);
      message.error('Something went wrong');
    },
  });

  const [updateOldPayPalSubscription] = useMutation(UPDATE_OLD_PAYPAL_SUBSCRIPTION, {
    async onCompleted(data) {
      if (data) {
        console.log(data, '=-=-=>data');
        startPolling(1000);
        setTimeout(async () => {
          setLoading(false);
          history.push('/plans');
          stopPolling();
          const updatedUser = await refetchUser();
          if (updatedUser?.data?.fetchUser) {
            userVar({ ...user, ...updatedUser?.data?.fetchUser });
          }
        }, 10000);
      }
    },
    onError(error) {
      setLoading(false);
      message.error('Something went wrong');
    },
  });

  useEffect(() => {
    loadUser();
    return () => {
      setUsersTeam([]);
    };
  }, [loadUser]);

  useEffect(() => {
    if (user?.paymentStatus?.payments && !usersTeam.length && !loadingTeamMembers) {
      setLoadingTeamMembers(true);
      loadTeamMembers();
      loadInvoices();
      if (user?.paymentStatus?.canLock) {
        trackEvent('SawLocked');
      }
    }
  }, [user, loadTeamMembers, trackEvent]); //eslint-disable-line

  useEffect(() => {
    if (user?.paymentStatus?.payments && !loading && !loadingTeamMembers) {
      const stripestatus = utils.getURLParam('stripestatus');
      const cardstatus = utils.getURLParam('cardstatus');
      const paypalToken = utils.getURLParam('token');
      const paypalstatus = utils.getURLParam('paypalstatus');

      if (stripestatus === 'success') {
        setLoading(true);
        const session_id = utils.getURLParam('session_id');
        if (session_id) {
          setTimeout(() => {
            setLoading(false);
            history.push('/dashboard?new=true');
          }, 2000);
        }
      } else if (stripestatus === 'cancel') {
        message.error('Transaction cancelled');
        history.push('/dashboard');
      }

      if (cardstatus === 'success') {
        setLoading(true);
        const session = utils.getURLParam('session_id');
        updateStripeCard({
          variables: {
            session,
          },
        });
      } else if (cardstatus === 'cancel') {
        message.error('Card update cancelled');
      }

      if (!paypalstatus && paypalToken) {
        setLoading(true);
        updateOldPayPalSubscription({
          variables: {
            token: paypalToken,
          },
        });
      } else if (paypalstatus === 'cancel') {
        message.error('Subscription update cancelled');
        history.push('/plans');
      }
    }
  }, [history, user, updateStripeCard, updateOldPayPalSubscription]); //eslint-disable-line

  useEffect(() => {
    document.title = 'Prospero - Plans';
  });

  const handleStripeClick = async (price, mutationInfo) => {
    setStripeLoading(true);

    trackEvent('click-upgrade', { type: 'upgrade', uid: user._id });
    trackEvent('click-buynow', {
      type: 'upgrade',
      subtype: price === 96 || price === 60 ? 'yearly' : 'monthly',
    });

    createStripeSession({
      variables: {
        price,
        membersToBeAdded: mutationInfo?.membersToBeAdded || [],
        membersToBeDeleted: mutationInfo?.membersToBeDeleted || [],
        planInfo: mutationInfo?.planInfo,
      },
    });
  };

  const handleChangeCard = () => {
    setStripeLoading(true);
    createStripeCardSession();
  };

  if (user?.teamId) {
    history.push('/dashboard');
  }

  if (loading || loadingUser || !user?.paymentStatus?.payments || loadingTeamMembers) {
    return <Loader />;
  }

  return (
    <div className="container narrow-container">
      <div className="plans">
        <Row className="plans-header">
          <PageHeader pageTitle="Billing" showButton={false} />
        </Row>
        <Row className="plans-content">
          <PlansInfo
            user={user}
            usersTeam={usersTeam}
            pendingUsersTeam={pendingUsersTeam}
            handleStripeClick={handleStripeClick}
            handleChangeCard={handleChangeCard}
            stripeLoading={stripeLoading}
            // createPayPalSubscription={createPayPalSubscription}
            startPolling={startPolling}
            stopPolling={stopPolling}
            refetchInvoices={refetchInvoices}
            refetchTeamMembers={refetchTeamMembers}
            refetchUser={refetchUser}
            setLoading={setLoading}
          />
          <BillingInfo
            invoiceLanguage={
              user.profile?.countryCode === 'IL'
                ? 'he'
                : user.profile?.language?.toLowerCase() === 'hebrew'
                ? 'he'
                : ''
            }
            loadingInvoices={loadingInvoices}
            invoices={invoices}
          />
        </Row>
      </div>
    </div>
  );
};

export default Plans;
