import React from 'react';
import { Menu, Dropdown, Checkbox, Radio } from 'antd';

import PropTypes from 'prop-types';
import './MultiSelect.scss';

const MenuItem = ({ item }) => (
  <Menu.Item key={item.id} className="menu-item">
    <Checkbox className="item-text">{item.name}</Checkbox>
  </Menu.Item>
);

const MultiSelect = ({
  className,
  menuClassName,
  filterList,
  iconComponent,
  onChangeFilter,
  disabled,
  showBorderBelowFirstItem,
  hideCheckBox,
  isCheckBox,
  isRadio,
  trash,
  onVisibleChangeHandle,
  showHeader,
  headerTitle,
}) => {
  const onMenuItemClick = (e) => e.stopPropagation();

  const menuItems = (
    <>
      {showHeader && (
        <div className="menu-header">
          <span className="title">{headerTitle}</span>
        </div>
      )}
      <Menu
        className={`${showBorderBelowFirstItem && 'multi-select-menu-active'} ${menuClassName}`}>
        {filterList.map((item) => (
          <Menu.Item key={item.id} className="menu-item" disabled={disabled}>
            <div className="menu-item_" onClick={onMenuItemClick}>
              {isRadio ? (
                <Radio
                  className={`item-text ${hideCheckBox ? 'hide-multi-checkbox' : ''}`}
                  checked={item.checked}
                  onChange={() => onChangeFilter(item)}
                  onClick={onMenuItemClick}>
                  {item.name}
                </Radio>
              ) : isCheckBox ? (
                <Checkbox
                  className={`item-text ${hideCheckBox ? 'hide-multi-checkbox' : ''}`}
                  checked={item.checked}
                  onChange={() => onChangeFilter(item)}
                  onClick={onMenuItemClick}>
                  {item.name}
                </Checkbox>
              ) : null}
            </div>
          </Menu.Item>
        ))}
      </Menu>
    </>
  );
  return (
    <Dropdown
      overlayClassName={`multi-select ${className}`}
      overlay={menuItems}
      disabled={trash ? true : false}
      trigger={['click']}
      onVisibleChange={(value) => onVisibleChangeHandle(value)}>
      {iconComponent}
    </Dropdown>
  );
};

MenuItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
  }).isRequired,
};

MultiSelect.defaultProps = {
  showBorderBelowFirstItem: true,
  isCheckBox: true,
  isRadio: false,
  onVisibleChangeHandle: () => {},
  showHeader: false,
};

MultiSelect.propTypes = {
  filterList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  iconComponent: PropTypes.shape({}),
  onChangeFilter: PropTypes.func.isRequired,
  showBorderBelowFirstItem: PropTypes.bool,
  isCheckBox: PropTypes.bool,
  isRadio: PropTypes.bool,
  onVisibleChangeHandle: PropTypes.func,
  showHeader: PropTypes.bool,
  headerTitle: PropTypes.string,
};

export default MultiSelect;
