import React, { useState, useEffect } from 'react';
import { Card, message } from 'antd';
import { useMutation, useQuery, useReactiveVar, useLazyQuery } from '@apollo/client';
import moment from 'moment';

import PageHeader from 'components/PageHeader';
import UserItem from './components/UserItem';
import EmptyUser from './components/EmptyUser';
import EditUser from './components/EditUser';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import Loader from 'components/Loader';
import AddUser from './components/AddUser';

import { getUserId, userVar } from 'graphql/cache';
import { FETCH_TEAM_MEMBERS } from 'graphql/queries/usersTeamQueries';
import { USER_PAYMENT_DETAILS } from 'graphql/queries/userQueries';
import {
  UPDATE_TEAM_MEMBER,
  MANAGE_TEAM_MEMBERS,
  DELETE_TEAM_MEMBER,
} from 'graphql/mutations/usersTeamMutation';

import './UsersTeam.scss';

const daystrial = parseInt(process.env.REACT_APP_DAYS_TRAIL) || 14;

const UsersTeam = ({ history }) => {
  const user = useReactiveVar(userVar);

  const [usersTeam, setUsersTeam] = useState([]);
  const [showEditUserModal, setEditUserModal] = useState(false);
  const [showAddMultipleUserModal, setShowMultipleUserModal] = useState(false);
  const [confirmModal, showConfirmModal] = useState(false);
  const [modalType, setModalType] = useState('add');
  const [selectedUser, setSelectedUser] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [plan, setPlan] = useState(
    (!user?.paymentStatus?.canLock && user?.paymentStatus?.subType) || 'monthly'
  );

  const handleManageTeamMembersResponse = (member, successCallback, errorCallback) => {
    if (member.error) {
      message.error(
        `User ${member.user.emails[0].address} addtion failed: ${
          member.error.match(/E11000/) ? 'User already exists' : member.error
        }`
      );
      errorCallback();
    } else {
      successCallback(member.user);
      message.success(`User ${member.user.emails[0].address} added successfully`);
    }
  };

  useQuery(USER_PAYMENT_DETAILS, {
    variables: { id: getUserId() },
    skip: !getUserId() || user?.paymentStatus?.payments?.length || user?.teamId,
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      const { fetchUser } = res;
      userVar({ ...user, ...fetchUser });
      setPlan(
        (!fetchUser?.paymentStatus?.canLock && fetchUser?.paymentStatus?.subType) || 'monthly'
      );
    },
  });

  const [loadTeamMembers, { loading: isFetchingTeamMembers }] = useLazyQuery(FETCH_TEAM_MEMBERS, {
    variables: { id: getUserId() },
    skip: !getUserId() || user,
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ fetchTeamMembers }) => setUsersTeam(fetchTeamMembers),
  });

  const [manageTeamMembers] = useMutation(MANAGE_TEAM_MEMBERS, {
    onCompleted: ({ manageTeamMembers }) => {
      let updatedUsersTeam = [...usersTeam];
      let error = false;

      const successCallback = (type) => {
        return (member) => {
          if (type === 'add') {
            updatedUsersTeam.push(member);
          } else {
            const userIndex = updatedUsersTeam.findIndex(
              (teamMember) => teamMember._id === member._id
            );
            if (userIndex) {
              updatedUsersTeam.splice(userIndex, 1);
            }
          }
        };
      };

      const errorCallback = () => {
        error = true;
      };

      manageTeamMembers?.add?.forEach((member) =>
        handleManageTeamMembersResponse(member, successCallback('add'), errorCallback)
      );
      manageTeamMembers?.delete?.forEach((member) =>
        handleManageTeamMembersResponse(member, successCallback('delete'), errorCallback)
      );

      const paymentResponse = manageTeamMembers?.paymentResponse;

      if (paymentResponse?.error) {
        message.error(paymentResponse?.error);
        setIsSaving(false);
        return setPlan(user?.paymentStatus?.subType);
      } else if (paymentResponse?.link) {
        window.open(paymentResponse.link, '_self');
      }

      setTimeout(async () => {
        setUsersTeam(updatedUsersTeam);
        setIsSaving(false);
        if (user?.paymentStatus?.subType && user?.paymentStatus?.subType !== plan) {
          message.success('Plan switched successfully');
        }
        if (!error) {
          setShowMultipleUserModal(false);
        }
      }, 10000);
    },
    onError: (error) => {
      setIsSaving(false);
      message.error(`User add failed: ${error.message}`);
    },
  });

  const [updateTeamMember] = useMutation(UPDATE_TEAM_MEMBER, {
    onError: (error) => {
      setIsSaving(false);
      message.error(`User ${modalType === 'add' ? 'added' : 'updated'} failed: ${error.message}`);
    },
  });

  const [deleteTeamMember] = useMutation(DELETE_TEAM_MEMBER, {
    onCompleted: ({ deleteTeamMember }) => {
      if (deleteTeamMember) {
        let updatedUsersTeam = usersTeam.filter((team) => team._id !== selectedUser._id);

        setUsersTeam(updatedUsersTeam);
        setSelectedUser('');
        setIsSaving(false);
        showConfirmModal(false);

        if (deleteTeamMember?.paymentResponse?.link) {
          window.open(deleteTeamMember?.paymentResponse?.link, '_self');
        }

        message.success('User deleted successfully');
      }
    },
    onError: (error) => {
      console.log(error);
      message.error('User deletion failed');
    },
  });

  useEffect(() => {
    if (user && user.teamId) {
      history.push('/dashboard');
    }
    if (user && !usersTeam.length && !isFetchingTeamMembers) {
      loadTeamMembers();
    }
  }, [user, usersTeam, history]); //eslint-disable-line

  useEffect(() => {
    document.title = 'Prospero - Team';
  });

  const onEditUserModalCancel = () => {
    setEditUserModal(false);
  };

  const deleteUser = () => {
    showConfirmModal(true);
  };

  const addOreditUser = ({ actionType, user }) => {
    const type = actionType ? actionType : 'add';
    setModalType(actionType);
    if (type === 'edit') {
      setSelectedUser(user);
      setEditUserModal(true);
    } else {
      setShowMultipleUserModal(true);
    }
  };

  const onConfirmDelete = () => {
    setIsSaving(true);
    const { _id } = selectedUser;

    deleteTeamMember({
      variables: {
        uid: _id,
      },
    });
  };

  const onAddOrUpdateConfirm = ({
    membersToBeAdded,
    membersToBeDeleted,
    planInfo,
    editUserInfo,
  }) => {
    setIsSaving(true);
    if (modalType === 'add') {
      manageTeamMembers({
        variables: {
          membersToBeAdded,
          membersToBeDeleted,
          planInfo,
        },
      });
    } else {
      const { userName, email, password, userImage, role } = editUserInfo;

      updateTeamMember({
        variables: {
          member: {
            _id: selectedUser._id,
            username: userName,
            email,
            password,
            userImage,
            role,
          },
        },
      }).then(({ data }) => {
        if (data) {
          let updatedUser = JSON.parse(JSON.stringify(selectedUser));
          updatedUser['profile'] = Object.assign(updatedUser.profile, {
            name: userName,
            image: userImage,
          });
          updatedUser['roles'] = [role];
          updatedUser['emails'] = [{ address: email }];

          let updatedUsersTeam = usersTeam.map((team) =>
            team._id === updatedUser._id ? updatedUser : team
          );

          setUsersTeam(updatedUsersTeam);
          setSelectedUser('');
          setEditUserModal(false);

          message.success(`User ${modalType === 'add' ? 'added' : 'updated'} successfully`);
        }
        setIsSaving(false);
      });
    }
  };

  const getTrialTime = () => {
    if (!user) {
      return 666;
    }

    const timespan = new Date() - new Date(user.stopperAnchor || user.createdAt);
    const dayspan = timespan / 1000 / 60 / 60 / 24;

    return daystrial - dayspan;
  };

  if (isFetchingTeamMembers) {
    return <Loader />;
  }

  const trialTime = getTrialTime();

  return (
    <div className="container">
      <div className="user-page">
        <PageHeader
          pageTitle="Users"
          buttonText="ADD NEW USER"
          showButton={!!usersTeam.length}
          onButtonClick={() => addOreditUser({ actionType: 'add' })}
        />
        {usersTeam.length ? (
          <Card>
            {usersTeam.map((item) => (
              <UserItem
                key={item._id}
                userName={item.profile.name}
                email={item.emails[0].address}
                position={item.roles && item.roles[0]}
                totalProposals={item.props.length}
                createdDate={moment(item.createdAt).format('DD.MM.YY')}
                image={item.profile.image}
                deleteUser={() => {
                  setSelectedUser(item);
                  deleteUser();
                }}
                editUser={() => addOreditUser({ actionType: 'edit', user: item })}
              />
            ))}
          </Card>
        ) : (
          <EmptyUser
            addOreditUser={addOreditUser}
            planType={
              user?.planType
                ? user.planType
                : new Date(user.createdAt) > new Date(process.env.REACT_APP_PLAN_REVISE_DATE)
                ? 'new'
                : ''
            }
          />
        )}

        {showEditUserModal && (
          <EditUser
            selectedUser={selectedUser}
            visible={showEditUserModal}
            onCancel={onEditUserModalCancel}
            type={modalType}
            onConfirm={onAddOrUpdateConfirm}
          />
        )}

        {showAddMultipleUserModal && (
          <AddUser
            user={user}
            usersTeam={usersTeam}
            pendingUsersTeam={usersTeam.filter((team) => team.emails[0].verified === false)}
            plan={plan}
            setPlan={() => {}}
            trial={trialTime >= 0 ? true : false}
            trialTime={trialTime}
            className="add-user-team-modal"
            visible={showAddMultipleUserModal}
            onCancel={() => setShowMultipleUserModal(false)}
            onConfirm={(_, mutationInfo) => onAddOrUpdateConfirm(mutationInfo)}
            setIsSaving={setIsSaving}
            isSaving={isSaving}
            userTeamsPage={true}
          />
        )}

        {confirmModal && (
          <ConfirmModal
            title={`Delete ${selectedUser?.profile?.name}?`}
            body={`Are you sure you want to delete this user?`}
            visible={confirmModal}
            cancelText="CANCEL"
            confirmText="DELETE"
            onConfirm={onConfirmDelete}
            onCancel={() => (isSaving ? {} : showConfirmModal(false))}
            isSaving={isSaving}
            maskClosable={!isSaving}
          />
        )}
      </div>
    </div>
  );
};

export default UsersTeam;
