import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, AutoComplete } from 'antd';

const InvoicingInfo = ({
  CountriesCode,
  country,
  address,
  companyname,
  vatnumber,
}) => {
  const [options, setOptions] = useState([{ label: country, value: country }]);

  const onSearch = (searchText) => {
    let newOptions = [];

    !searchText
      ? []
      : Object.keys(CountriesCode).forEach((countryCode) => {
          if (CountriesCode[countryCode].name.search(new RegExp(searchText, 'i')) !== -1)
            newOptions.push({
              label: CountriesCode[countryCode].name,
              value: CountriesCode[countryCode].name,
            });
        });

    setOptions(newOptions);
  };

  return (
    <div className="invoicing-info">
      <Form.Item
        label="Company Name"
        name="companyname"
        labelCol={false}
        rules={[{ required: true, message: 'Company Name Required!' }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Address"
        name="address"
        labelCol={false}
        rules={[{ required: true, message: 'Address Required!' }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Country"
        name="country"
        labelCol={false}
        rules={[{ required: true, message: 'Country Required!' }]}>
        <AutoComplete options={options} onSearch={onSearch} />
      </Form.Item>

      <Form.Item
        label="VAT / Business Number"
        name="vatnumber"
        labelCol={false}
        rules={[{ message: 'Vat Number Required!' }]}>
        <Input type="text" name="vatnumber" autoComplete="vatnumber" />
      </Form.Item>
    </div>
  );
};

InvoicingInfo.propTypes = {
  country: PropTypes.string,
  companyname: PropTypes.string,
  address: PropTypes.string,
  vatnumber: PropTypes.string,
  CountriesCode: PropTypes.instanceOf(Object).isRequired,
};

export default InvoicingInfo;
