import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Avatar, Layout, Tooltip } from 'antd';
import CircleTickIcon from 'components/Icons/CircleTickIcon';
import InfoIcon from 'components/Icons/InfoIcon';
import PatronsLogos from 'components/Icons/PatronsLogos';
import { FEATURES_LIST_1, FEATURES_LIST_2 } from 'constants/index';
import images from 'constants/images';

const { Sider } = Layout;

const Perks = ({ user, usersTeam, newUsersCount }) => {
  const renderFeaturesList = (list, isActive) => {
    return list.map((item) => (
      <Row key={item.text} className={`feature-list ${isActive ? '' : 'feature-list-disabled'}`}>
        <CircleTickIcon className="feature-list-check-icon" />
        <div className="feature-list-info">
          <span className="feature-list-text">{item.text}</span>
          <Tooltip title={item.tooltip} overlayClassName="feature-list-info-tooltip">
            <InfoIcon className="feature-list-info-icon" />
          </Tooltip>
        </div>
      </Row>
    ));
  };

  return (
    <Sider width={350} className="plans-info-sider" breakpoint="sm" collapsedWidth="50">
      <Col span={24} className="plans-info">
        <p className="plans-information-header">This Is What You Get</p>
        <Row className="plans-information">
          <Row span={7}>{renderFeaturesList(FEATURES_LIST_1, true)}</Row>
          <Row span={9}>
            {renderFeaturesList(FEATURES_LIST_2, usersTeam.length + newUsersCount ? true : false)}
          </Row>
        </Row>
      </Col>
      <hr className="divide" />
      <Col>
        <Row className="plans-review">
          <div className="testimony">
            <Row className="plans-reviewer">
              <Avatar src={images.PLAN} size={64} />
              <Col className="plans-reviewer-info">
                <h3 className="author-name">Hadar Chen</h3>
                <span className="author-title">Co-founder & Finance</span>
              </Col>
            </Row>
            <p className="comment">
              “Prospero is helping us to achieve exhilarating and invigorating results. We're
              receiving more”
            </p>
          </div>
          <PatronsLogos className="plans-review-service-icon" />
        </Row>
      </Col>
    </Sider>
  );
};

Perks.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  usersTeam: PropTypes.instanceOf(Array),
  newUsersCount: PropTypes.number,
};

export default Perks;
