/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button as AntdButton,
  Input,
  Select,
  message,
  Popconfirm,
  Tooltip,
  Radio,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { UPDATE_PROPOSAL_MUTATION } from 'graphql/mutations/proposalMutation';
import { UPDATE_SLACK_NOTIFICATIONS } from 'graphql/mutations/integrationMutation';
import { PROPOSAL_SUBSCRIPTION_TOPIC } from 'constants/index';
import DropdownIcon from 'components/Icons/DropdownIcon';
import CircleTickIcon from 'components/Icons/CircleTickIcon';
import SliderIcon from 'components/Icons/SliderIcon';
import SlackNotificationModal from 'components/SlackNotificationModal';
import { userVar } from 'graphql/cache';
import useSuspiciousLink from 'hooks/useSuspiciousLink';

import './IntegrationItem.scss';

const INTEGRATION_BUTTON_TYPES = {
  connected: {
    text: 'connected',
    color: '#01c34f',
    borderLeftColor: '#01c34f !important',
    background: '#dcf6e7',
    border: '#dcf6e7',
    onHoverText: 'disconnect',
    onHoverColor: '#f03f3b',
    onHoverBorder: '#f03f3b',
    onHoverBackground: '#ffffff',
  },
  disconnecting: {
    text: 'disconnecting...',
    color: '#f03f3b !important',
    borderLeftColor: '#f03f3b',
    background: '#ffffff',
    border: '#f03f3b',
    onHoverText: 'disconnecting...',
    onHoverColor: '#f03f3b',
    onHoverBorder: '#f03f3b',
    onHoverBackground: '#ffffff',
  },
  disconnected: {
    text: 'connect',
    borderLeftColor: '#f03f3b',
    color: '#C4C4C4',
    background: '#FFFFFF',
    border: '#C4C4C4',
    onHoverText: 'connect',
    onHoverColor: '#01c34f',
    onHoverBorder: '#01c34f',
    onHoverBackground: '#ffffff',
  },
  connecting: {
    text: 'connecting...',
    borderLeftColor: '#01c34f !important',
    color: '#01c34f !important',
    background: '#FFFFFF',
    border: '#01c34f',
    onHoverText: 'connecting...',
    onHoverColor: '#01c34f',
    onHoverBorder: '#01c34f',
    onHoverBackground: '#FFFFFF',
  },
};
const { Option } = Select;

const Item = styled(Row)`
  border-left: ${(props) => `4px solid ${INTEGRATION_BUTTON_TYPES[props.status].borderLeftColor}`};
`;

const Button = styled(AntdButton)`
  color: ${(props) => INTEGRATION_BUTTON_TYPES[props.status].color}!important;
  background: ${(props) => INTEGRATION_BUTTON_TYPES[props.status].background} !important;
  border: ${(props) => `1px solid ${INTEGRATION_BUTTON_TYPES[props.status].border}`}!important;
  &:hover {
    color: ${(props) => INTEGRATION_BUTTON_TYPES[props.status].onHoverColor}!important;
    border: ${(props) =>
      `1px solid ${INTEGRATION_BUTTON_TYPES[props.status].onHoverBorder} !important`};
    background: ${(props) => INTEGRATION_BUTTON_TYPES[props.status].onHoverBackground}!important;
  }
  a:hover span {
    display: none;
  }
`;

const IntegrationItem = ({
  type,
  name,
  subname,
  description,
  integrationLink,
  status,
  image,
  logo,
  handleClick,
  isModalItem,
  currencies,
  proposal,
  integrationOptions,
  userRole,
  user,
  logoSubtitle,
  saveProposal,
  extendSection,
  integrationData,
}) => {
  const { checkSuspiciousUrl } = useSuspiciousLink({ proposalId: proposal._id });
  const stripeType =
    proposal?.stripe?.type ||
    (integrationData?.stripe_user_id && integrationData.isActive ? 'stripe' : '');

  const [hover, onHover] = useState(false);
  const [paymentButtonText, setPaymentButtonText] = useState('Edit');
  const [currency, setCurrency] = useState('USD');
  const [clientPayableAmount, setClientPaymentAmount] = useState(null);
  const [clientPayableLabel, setClientPayableLabel] = useState(null);
  const [clientPayableLink, setClientPayableLink] = useState(null);
  const [enablePayment, setEnablePayment] = useState(proposal.enablePayment || 'before_sign');
  const [selectValue, setSelectValue] = useState(stripeType);
  const [selectError, setSelectError] = useState(false);
  const [slackNotificationModal, showSlackNotificationModal] = useState(false);

  useEffect(() => {
    if (proposal && name === 'stripe' && isModalItem) {
      setClientPaymentAmount(proposal.clientPayableAmount);
      setCurrency(proposal.clientPayableCurrency);
      setClientPayableLabel(proposal.clientPayableLabel || 'Make Payment');
      setClientPayableLink(proposal.clientPayableLink);
    }
  }, []);

  const [updateProposal] = useMutation(UPDATE_PROPOSAL_MUTATION, {});
  const [updateSlackNotifications] = useMutation(UPDATE_SLACK_NOTIFICATIONS, {});

  const updateNotifications = async (notifications) => {
    try {
      const result = await updateSlackNotifications({
        variables: {
          notifications: notifications,
        },
      });
      const userData = { ...user, slack: result?.data?.updateSlackNotifications?.slack };
      userVar({ ...userData });
    } catch (err) {
      message.error(`Problem updating slack notifications`);
    }
  };

  const saveEditPaymentDetails = () => {
    if (paymentButtonText === 'Save') {
      if (!clientPayableLabel || clientPayableLabel.length === 0) {
        message.error('Payment label is required');
        return;
      }
      let paymentDetails;
      if (selectValue === 'stripe') {
        paymentDetails = {
          clientPayableAmount,
          clientPayableLabel,
          clientPayableCurrency: currency,
        };
      } else {
        try {
          new URL(clientPayableLink);
        } catch (_) {
          return message.error(`Enter a valid link`);
        }
        paymentDetails = {
          clientPayableLink,
        };

        // check for spam
        setTimeout(() => checkSuspiciousUrl(clientPayableLink), 4000);
      }

      const updatedProposal = {
        id: proposal._id,
        _id: proposal._id,
        pid: proposal.pid,
        uid: proposal.uid,
        edited: new Date(),
        enablePayment,
        ...paymentDetails,
      };

      if (saveProposal) {
        saveProposal(updatedProposal);
      } else {
        updateProposal({
          variables: {
            topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
              proposal?.channel || proposal?.auid || proposal?.uid
            }`,
            updateType: 'edit',
            proposal: updatedProposal,
          },
          onError: () => {
            message.error(`Problem updating stripe info`);
          },
        });
      }
    }

    setPaymentButtonText((prevState) => (prevState === 'Edit' ? 'Save' : 'Edit'));
  };

  const toggleHasPayment = (val) => {
    setEnablePayment(val);

    const updatedProposal = {
      id: proposal._id,
      _id: proposal._id,
      pid: proposal.pid,
      uid: proposal.uid,
      edited: new Date(),
      enablePayment: val,
    };

    if (saveProposal) {
      saveProposal(updatedProposal);
    } else {
      updateProposal({
        variables: {
          topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
            proposal?.channel || proposal?.auid || proposal?.uid
          }`,
          updateType: 'edit',
          proposal: updatedProposal,
        },
        onError: () => {
          message.error(`Problem updating payment info`);
        },
      });
    }
  };

  const removeIntegration = () => {
    const updatedProposal = {
      id: proposal._id,
      _id: proposal._id,
      pid: proposal.pid,
      uid: proposal.uid,
      edited: new Date(),
      stripe: '',
      enablePayment: '',
      disableSig: false,
      clientPayableLink: '',
      clientPayableAmount: '',
      clientPayableLabel: '',
    };

    if (saveProposal) {
      saveProposal(updatedProposal);
    } else {
      updateProposal({
        variables: {
          topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
            proposal?.channel || proposal?.auid || proposal?.uid
          }`,
          updateType: 'edit',
          proposal: updatedProposal,
        },
        onError: () => {
          message.error(`Problem updating stripe info`);
        },
      });
    }
    setSelectError(false);
    setSelectValue(null);
    handleClick(null);
  };

  const handleSelect = (value) => {
    let selectTempValue = value;
    if (stripeType && proposal?.stripe?.isActive && selectTempValue !== stripeType) {
      setSelectError(true);
      return;
    }
    setSelectValue(selectTempValue);
  };

  const handleSlackClick = () => {
    showSlackNotificationModal(true);
  };

  const handleConnectClick = () => {
    if (status === 'connected') setSelectValue(null);
    if (name === 'stripe' && selectValue) {
      if (
        (selectValue === 'custom payment link' && clientPayableLink) ||
        selectValue === 'stripe'
      ) {
        handleClick(selectValue);
      } else {
        return message.error('Payment information is incomplete');
      }
    } else {
      handleClick(selectValue);
    }
  };

  const isIntegrationButtonDisabled = userRole === 'editor' && !isModalItem;

  return (
    <Item className="integration-item" align-items="center" status={status} justify="center">
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: isModalItem ? 12 : 7 }}
        xl={{ span: isModalItem ? 10 : 6 }}>
        <div className="item-brand-name">
          {integrationOptions?.length && proposal ? (
            <Popconfirm
              zIndex={1009}
              title={() => (
                <>
                  <Row>Remove {stripeType === 'stripe' ? 'Stripe' : 'Custom'} integration?</Row>
                  <Row>You only able to connect one payment method to your proposals</Row>
                  <div className="button-wrapper">
                    <AntdButton
                      className="button confirm"
                      type="primary"
                      onClick={removeIntegration}>
                      Remove
                    </AntdButton>
                    <AntdButton
                      className="button cancel"
                      type="primary"
                      onClick={() => setSelectError(false)}>
                      Cancel
                    </AntdButton>
                  </div>
                </>
              )}
              overlayClassName="popconfirm-integration-item"
              visible={selectError}
              placement="bottomRight"
              icon={() => {}}>
              <Select
                value={selectValue}
                suffixIcon={<DropdownIcon />}
                placeholder={'Select Payment Type...'}
                onChange={handleSelect}>
                {integrationOptions.map((integrationOption, index) => (
                  <Option
                    key={index}
                    value={integrationOption.name}
                    className="item-brand-name-select-option">
                    <Row className="item-select-row">
                      <Col>
                        {integrationOption.image && (
                          <img
                            src={integrationOption.image}
                            alt={`prospero-${integrationOption.name}`}
                          />
                        )}
                        <span className="item-name"> {integrationOption.name}</span>
                      </Col>
                      <Col>
                        <CircleTickIcon />
                      </Col>
                    </Row>
                  </Option>
                ))}
              </Select>
            </Popconfirm>
          ) : (
            <>
              {image && (
                <img
                  src={image}
                  style={type === 'quickbooks' ? { height: '27px' } : null}
                  alt={`prospero-${name}`}
                />
              )}
              {logo || null}

              <Col>
                {name !== 'quickbooks' && (
                  <Row>
                    <span
                      className="item-name"
                      style={{
                        textTransform: (name === 'morning' || name === 'monday') && 'none',
                      }}>
                      {' '}
                      {name}{' '}
                    </span>
                    {subname !== null && <span className="item-sub-name"> {subname} </span>}
                  </Row>
                )}
                {logoSubtitle}
              </Col>
            </>
          )}
        </div>

        {isModalItem && (
          <span className="item-status-description">
            {status === 'connected' ? (
              <>
                {type === 'chatway' ? (
                  description
                ) : (
                  <>
                    The integration is
                    <span className="item-active"> &nbsp;active</span>
                  </>
                )}
                {type === 'slack' ? (
                  <>
                    <AntdButton
                      className="button slack-notification-settings"
                      icon={<SliderIcon className="slack-slider-icon" />}
                      onClick={handleSlackClick}>
                      Settings
                    </AntdButton>
                    <SlackNotificationModal
                      zIndex={1009}
                      title="Slack Settings"
                      body=""
                      visible={slackNotificationModal}
                      updateNotifications={updateNotifications}
                      slackNotifications={user?.slack?.notifications}
                      cancelText="CANCEL"
                      confirmText=""
                      showSlackNotificationModal={showSlackNotificationModal}
                      onCancel={() => showSlackNotificationModal(false)}
                    />
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              description
            )}
          </span>
        )}
      </Col>

      {!isModalItem && (
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 10 }}
          className="item-description">
          {status === 'connected' ? (
            <>
              {type === 'chatway' ? (
                description
              ) : (
                <>
                  The integration is
                  <span className="item-active"> &nbsp;active</span>
                </>
              )}
              {type === 'slack' ? (
                <>
                  <AntdButton
                    className="button slack-notification-settings"
                    icon={<SliderIcon className="slack-slider-icon" />}
                    onClick={handleSlackClick}>
                    Settings
                  </AntdButton>
                  <SlackNotificationModal
                    zIndex={1009}
                    title="Slack Settings"
                    body="This action cannot be undone"
                    visible={slackNotificationModal}
                    updateNotifications={updateNotifications}
                    slackNotifications={user?.slack?.notifications}
                    cancelText="CANCEL"
                    confirmText="YES, DELETE"
                    showSlackNotificationModal={showSlackNotificationModal}
                    onCancel={() => showSlackNotificationModal(false)}
                  />
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            description
          )}
        </Col>
      )}

      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: isModalItem ? 12 : 9 }}
        xl={{ span: isModalItem ? 14 : 8 }}
        className="value">
        <a href={integrationLink} className="integration-link" target="_blank" rel="noreferrer">
          Integration guide
        </a>
        <Button
          onMouseEnter={() => !isIntegrationButtonDisabled && onHover(true)}
          onMouseLeave={() => !isIntegrationButtonDisabled && onHover(false)}
          className="integration-button"
          status={status}
          disabled={isIntegrationButtonDisabled}
          onClick={handleConnectClick}>
          {hover
            ? INTEGRATION_BUTTON_TYPES[status].onHoverText
            : INTEGRATION_BUTTON_TYPES[status].text}
        </Button>
      </Col>

      {name === 'stripe' && isModalItem && selectValue && (
        <>
          <div className="client-payment-details item-status-description">
            {proposal?.stripe?.stripe_user_id && status === 'connected' ? (
              <div className="payment">
                <div className="flex">
                  <Row gutter={{ md: 4, lg: 8 }}>
                    <Col className="payment-integration-input" span={8}>
                      <div className="input-wrapper">
                        <label className="label">Payment Amount</label>
                        <Input
                          onChange={(e) => setClientPaymentAmount(e.target.value)}
                          placeholder="First Payment Amount For Customers"
                          type="number"
                          value={clientPayableAmount}
                          disabled={paymentButtonText === 'Edit'}
                        />
                      </div>
                    </Col>
                    <Col className="payment-integration-input" span={8}>
                      <div className="input-wrapper">
                        <label className="label">Payment Label</label>
                        <Input
                          value={clientPayableLabel}
                          placeholder="Make Payment"
                          type="string"
                          onChange={(e) => setClientPayableLabel(e.target.value)}
                          disabled={paymentButtonText === 'Edit'}
                        />
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="input-wrapper">
                        <label className="label">&nbsp;</label>
                        <Select
                          value={currency}
                          onChange={(val) => setCurrency(val)}
                          suffixIcon={<CaretDownOutlined color="red" />}
                          disabled={paymentButtonText === 'Edit'}>
                          {currencies.map((item) => (
                            <Option
                              className="client-payment-currency-option"
                              key={item.label}
                              value={item.value}>
                              {item.label?.toUpperCase()}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="input-wrapper">
                        <label className="label">&nbsp;</label>
                        <AntdButton className="save-edit-button" onClick={saveEditPaymentDetails}>
                          {paymentButtonText}
                        </AntdButton>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="toggler">
                  <label className="label">Enable Payment</label>
                  <div className="switch-toggle-button">
                    <Radio.Group
                      value={enablePayment}
                      onChange={() => {
                        enablePayment === 'before_sign'
                          ? toggleHasPayment('after_sign')
                          : toggleHasPayment('before_sign');
                      }}>
                      <Tooltip
                        placement="top"
                        title="The client needs to pay before signing the proposal.">
                        <Radio.Button value="before_sign">
                          <span className="switch-toggle-text">BEFORE SIGN</span>
                        </Radio.Button>
                      </Tooltip>
                      <Tooltip
                        placement="bottom"
                        title="Client can pay after signing the proposal.">
                        <Radio.Button value="after_sign">
                          <span className="switch-toggle-text">AFTER SIGN</span>
                        </Radio.Button>
                      </Tooltip>
                    </Radio.Group>
                  </div>
                </div>
              </div>
            ) : selectValue === 'custom payment link' ? (
              <Row className="payment">
                <div className="input-wrapper">
                  <label className="label">Payment Link</label>
                  <Input
                    onChange={(e) => setClientPayableLink(e.target.value)}
                    placeholder="Enter Payment link For Customers"
                    type="text"
                    value={clientPayableLink}
                    disabled={paymentButtonText === 'Edit'}
                  />
                </div>
                {((proposal?.stripe?.stripe_user_id && status === 'connected') ||
                  selectValue === 'custom payment link') && (
                  <div className="col-flex">
                    <label className="label">&nbsp;</label>
                    <AntdButton className="save-edit-button" onClick={saveEditPaymentDetails}>
                      {paymentButtonText}
                    </AntdButton>
                  </div>
                )}
              </Row>
            ) : null}
          </div>
        </>
      )}
      {extendSection}
    </Item>
  );
};

IntegrationItem.defaultProps = {
  isModalItem: false,
  currencies: [],
  proposal: '',
  image: '',
  integrationOptions: [],
  userRole: '',
};

IntegrationItem.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  integrationLink: PropTypes.string.isRequired,
  image: PropTypes.string,
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleClick: PropTypes.func.isRequired,
  status: PropTypes.oneOf(['disconnected', 'connected', 'connecting', 'disconnecting']).isRequired,
  isModalItem: PropTypes.bool,
  currencies: PropTypes.arrayOf(PropTypes.shape({})),
  proposal: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  integrationOptions: PropTypes.instanceOf(Array),
  userRole: PropTypes.string,
  logoSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  integrationData: PropTypes.instanceOf(Object),
};

export default IntegrationItem;
